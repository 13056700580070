<template>
  <div>
    <div class="box">
      <resumeHeadS title="简历模板下载" />
      <div class="box-topImg">
        <img src="@/assets/image/resumeLogin/banner2.png" alt="" />
      </div>

      <!-- word模板区域 -->
      <div class="word-box">
        <div class="wordBoxlist" v-for="(item, index) in wordList" :key="index">
          <div class="list-title">{{ item.name }}</div>
          <div class="word-from">
            <div
              class="board"
              v-for="(text, indexTwo) in item.resumes"
              :key="indexTwo"
              @click="open(text)"
            >
              <div class="glass" @click="open(text)">
                <img src="@/assets/image/resumeLogin/放大镜.png" alt="" />
              </div>
              <el-image :src="text.cover" class="boardImg" lazy></el-image>
              <div class="download" @click.stop="wordFn(text.url)">立即下载</div>
              <div class="wrap-mask"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- 预览word弹窗部分 -->
      <el-dialog :visible.sync="centerDialogVisible" width="524px" center :show-close="showClose">
        <img v-if="itemObj" :src="itemObj.cover" alt="" class="word_Img" />

        <span slot="footer" class="dialog-footer">
          <el-button @click="wordFn(null)" class="wordFn">立即下载</el-button>
          <el-button @click="centerDialogVisible = false" class="close">关闭</el-button>
        </span>
      </el-dialog>
      <!-- 预览word弹窗部分 -->
    </div>

    <MenuStructure class="menuStructure" :control="true" type="/resume/resume_template" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import MenuStructure from '@/components/MenuStructure'
import resumeHeadS from '@/components/resumeHeadS.vue'

export default {
  components: {
    MenuStructure,
    resumeHeadS
  },
  data() {
    return {
      centerDialogVisible: false, //控制预览弹窗
      wordList: [], //word数组列表
      itemObj: null, //当前预览简历属性
      showClose: false
    }
  },
  methods: {
    ...mapActions(['getResumeLabelList', 'getUserInfo']),
    ...mapMutations({
      setToken: 'SET_TOKEN',
      removerContents: 'REMOVER_CONTENTS'
    }),
    async getResumeLabelListFn() {
      const res = await this.getResumeLabelList()
      this.wordList = res.data
    },
    open(item) {
      this.centerDialogVisible = true
      this.itemObj = item
    },
    wordFn(url) {
      window.location.href = url || this.itemObj.url
      this.centerDialogVisible = false
    },
    //退出登录按钮
    confirm() {
      this.removerContents()
      this.$router.push('/resume/login')
    }
  },
  created() {
    this.getResumeLabelListFn()
  },
  computed: {
    ...mapState(['userInfo'])
  }
}
</script>

<style scoped lang="stylus">
.box
  .box-topImg
    margin-bottom 24px
  .word-box
    width 1200px
    height auto
    background #FFFFFF
    box-shadow 0px 0px 20px 0px rgba(221, 221, 221, 0.4)
    border-radius 10px
    margin 0 auto
  .word-box
    padding 40px 50px 0 50px
    .wordBoxlist
      .list-title
        font-size 25px
        font-family Source Han Sans CN
        font-weight bold
        color #333333
        margin-bottom 26px
      .word-from
        display flex
        flex-wrap wrap
        .board
          cursor pointer
          width 200px
          height 282px
          border 0px solid #003DBB
          margin-right 25px
          margin-bottom 24px
          position relative
          box-shadow 0px 0px 20px 0px rgba(221, 221, 221, 0.4)
          border-radius 10px
          .glass
            width 30px
            height 30px
            background #FFFFFF
            border-radius 4px
            position absolute
            right 12px
            top 10px
            z-index 9999
            display none
            img
              width 16px
              height 16px
              margin 8px auto
          .download
            width 110px
            height 40px
            background #003DBB
            border-radius 4px
            font-size 18px
            font-family Source Han Sans CN
            font-weight 400
            color #FFFFFF
            line-height 40px
            text-align center
            position absolute
            z-index 999
            left 45px
            bottom 33px
            display none
        .download:hover
          opacity 0.8
        .glass:hover
          img
            width 20px
            height 20px
            margin 8px auto
        .board:hover
          .glass
            display block
          .download
            display block
          .wrap-mask:after
            position absolute
            top 0
            left 0
            content ''
            background-color rgba(0, 0, 0, 0.5)
            opacity 0.2
            z-index 99
            width 100%
            height 100%
            border-radius 10px
        .board:nth-child(5)
          margin-right 0px
        .board:nth-child(10)
          margin-right 0px
        .boardImg
          width 200px
          height 282px
          border-radius 10px
          img
            width 100%
            height 100%
  .wordImg
    width 524px
    height 765px
    background #FFFFFF
    border 0px solid #D1D1D1
    border-radius 6px
    img
      width 100%
      height 100%
  .word_Img
    border-radius 6px
    box-shadow 0px 0px 20px 0px rgba(0, 0, 0, 0.2)
  /deep/ .el-button.wordFn
    width 110px
    background #003DBB
    border-radius 4px
    font-size 18px
    font-family Source Han Sans CN
    font-weight 400
    color #FFFFFF
  /deep/ .el-button.wordFn:hover
    opacity 0.8
  /deep/ .el-button.close.el-button--default
    width 111px
    background #FFFFFF
    border 1px solid #CCCCCC
    border-radius 4px
    font-size 18px
    font-family Source Han Sans CN
    font-weight 400
    color #666666
  /deep/ .el-button.close.el-button--default:hover
    opacity 0.8
  /deep/ .el-dialog__header
    padding 0
  /deep/.el-dialog--center .el-dialog__body
    padding 25px 25px 9px
  /deep/ .el-dialog.el-dialog--center
    border-radius 5px
.menuStructure
  top 393px
  right 60px
  position fixed
</style>
